<template>
  <permissions-table
    :headers="headers"
    :permissions="permissions"
    @deletePermission="deletePermission($event)"
  />
</template>

<script>
import PermissionsTable from "../../../components/Tables/PermissionsTable.vue";
const _ = require("lodash");

export default {
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
        width: "150px",
      },
      {
        text: "Tag",
        align: "start",
        sortable: false,
        value: "tag_type",
        width: "150px",
      },
      {
        value: "actions",
        width: "130px",
        align: "end",
      },
    ],
  }),
  async mounted() {
    //Permissions
    await this.fetchPermissions();
  },
  computed: {
    permissions() {
      let arr = _.cloneDeep(this.$store.state.permissions); // clone data from store to allow mutation
      arr.forEach((item) => {
        item.name = item.name.split(item.tag_type)[0].replace(/_/g, " "); //Hide part of name after tag_type and hide underscores
        item.name = item.name.charAt(0).toUpperCase() + item.name.slice(1);
        item.tag_type =
          item.tag_type.charAt(0).toUpperCase() + item.tag_type.slice(1);
      });
      return arr;
    },
  },
  components: {
    PermissionsTable,
  },
  methods: {
    async deletePermission(id) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("deletePermission", id);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Permission deleted successfully!",
          group: "success",
        });
        
        await this.fetchPermissions();
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async fetchPermissions() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readPermissions");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>