<template>
  <v-card id="permissions">
    <ConfirmDelete
      :dialog="dialog"
      :item="permission"
      @delete="rundeleteFn($event)"
      @dismiss="dismissFn($event)"
    />
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col cols="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            rounded
            dense
            height="1"
            color="#3371E2"
          ></v-text-field>
        </v-col>
        <v-col cols="9">
          <span class="float-right">
            <v-btn
              small
              depressed
              outlined
              color="#8cc73e"
              class="custom-transform-class"
              to="/users-management/add-permission"
              >Add Permission</v-btn
            >
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="permissions"
      :search="search"
      hide-default-footer
      disable-pagination
    >
      <!-- eslint-disable-next-line -->
       <template v-slot:item.name="{ item }">
          <h5>{{ item.name }}</h5>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.tag_type="{ item }">
        <h5 class="mt-3">{{ item.tag_type }}</h5>
        <p v-if="item.client">{{ item.client.client_name }}</p>
        <p v-if="item.fb_ad_account">{{ item.fb_ad_account.name }}</p>
        
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-btn small class="mr-5" color="blue" icon @click="editPermission(item.id)">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn small color="red" icon @click="openModal(item)"> 
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import ConfirmDelete from "../ConfirmDelete.vue";

export default {
  components: {
    ConfirmDelete,
  },
  data: () => ({
    search: "",
    dialog: false,
    permission: {},
  }),
  props: {
    headers: Array,
    permissions: Array,
  },
  methods: {
    editPermission(id) {
        this.$router.push({name: 'update-permission', params: { id: id }})
    },
    rundeleteFn(id) {
      // Delete permission
      this.$emit("deletePermission", id);
    },
    dismissFn() {
      this.dialog = false;
    },
    async openModal(item) {
      this.dialog = !this.dialog;
      // this.modalOpen = true;
      this.permission = await item;
    },
  }
};
</script>

<style scoped>
#permissions {
  margin: 2.5rem 0;
}
</style>